import { ECompareCardVariant, ICompareCardProps } from "./CompareCard.types";

import Button from "~/components/atoms/Button";
import { Icon } from "~/components/atoms/Icons";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

const CompareCard: React.FC<ICompareCardProps> = ({
  id,
  variant,
  containerClass = "",
  thumbnail,
  title = "",
  onRemove,
  thumbnailClass = "",
  onAdd,
}) => {
  if (
    variant === ECompareCardVariant.EMPTY ||
    variant === ECompareCardVariant.SMALL_EMPTY
  ) {
    return (
      <div
        className={cn(
          "flex h-full w-full items-center justify-center rounded-xl border border-dashed border-gableGreen50",
          containerClass
        )}
      >
        {typeof onAdd === "function" ? (
          <Button
            variant="transparent"
            size="icon"
            onClick={() => onAdd()}
            className="!hover:bg-color flex cursor-pointer items-center justify-center rounded-full !bg-color !p-2 opacity-25"
          >
            <Icon
              TypeIcon={EIcon.PlusIcon}
              width={variant === ECompareCardVariant.EMPTY ? 18 : 12}
              height={variant === ECompareCardVariant.EMPTY ? 18 : 12}
              stroke="white"
            />
          </Button>
        ) : (
          <div className="!hover:bg-color flex items-center justify-center rounded-full !bg-color !p-2 opacity-25">
            <Icon
              TypeIcon={EIcon.PlusIcon}
              width={12}
              height={12}
              stroke="white"
              className="cursor-auto"
            />
          </div>
        )}
      </div>
    );
  }
  if (variant === ECompareCardVariant.SMALL_ADDED) {
    return (
      <div
        className={cn(
          "flex h-full w-full flex-col gap-3 rounded-xl",
          containerClass
        )}
      >
        <figure className="relative block">
          {typeof onRemove === "function" && (
            <Button
              variant="transparent"
              size="icon"
              onClick={() => {
                if (id) {
                  onRemove(id);
                }
              }}
              className="!hover:bg-rustyRed absolute right-2 top-2 z-10 flex cursor-pointer items-center justify-center rounded-full !bg-error !p-1"
            >
              <Icon TypeIcon={EIcon.TimesIcon} width={8} height={8} />
            </Button>
          )}
          <ImageResponsive
            imageData={thumbnail}
            className="h-17 w-full rounded-lg object-cover"
          />
          <Typo
            tag={ETypoTag.H5}
            variant={ETypoVariant.BODY_TITLE_12}
            color={ETypoColor.WHITE}
            className="absolute bottom-1 left-1 z-10 line-clamp-1"
          >
            {title}
          </Typo>
        </figure>
      </div>
    );
  }
  if (variant === ECompareCardVariant.ADDED) {
    return (
      <div
        className={cn(
          "flex h-full w-full flex-col gap-3 rounded-xl",
          containerClass
        )}
      >
        <figure className="relative block">
          {typeof onRemove === "function" && (
            <Button
              variant="transparent"
              size="icon"
              onClick={() => {
                if (id) {
                  onRemove(id);
                }
              }}
              className="!hover:bg-rustyRed absolute right-2 top-2 z-10 flex cursor-pointer items-center justify-center rounded-full !bg-error !p-1.5"
            >
              <Icon TypeIcon={EIcon.TimesIcon} width={16} height={16} />
            </Button>
          )}
          <ImageResponsive
            imageData={thumbnail}
            className={cn(
              "h-[120px] w-full rounded-xl object-center lg:h-[86px]",
              thumbnailClass
            )}
          />
        </figure>
        <Typo
          tag={ETypoTag.H5}
          variant={ETypoVariant.HEADER_16}
          color={ETypoColor.TEXT}
          className="line-clamp-2 whitespace-pre-wrap font-bold"
        >
          {title}
        </Typo>
      </div>
    );
  }
  return <></>;
};

export default CompareCard;
