import { IMediaImageUrls } from "~/entities/media";

export enum ECompareCardVariant {
  EMPTY = "empty",
  ADDED = "added",
  SMALL_ADDED = "small_added",
  SMALL_EMPTY = "small_empty",
}

export interface ICompareCardProps {
  thumbnail?: IMediaImageUrls;
  title?: string;
  id?: string;
  variant: ECompareCardVariant;
  containerClass?: string;
  thumbnailClass?: string;
  onRemove?: (id: string) => void;
  onAdd?: () => void;
}
